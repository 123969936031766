* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
.CardContainer {
  display: flex;
  flex-wrap: wrap;  
  position: relative;
  width: 100%; /* Cambiado a porcentaje */
  height: 100%; /* Cambiado a porcentaje */
  max-width: 33vh; /* Tamaño máximo */
  max-height: 40vh; /* Tamaño máximo */
  margin: 20px;
  perspective: 1000px;
  border-radius: 10px; /* Asegura que el contenedor también tenga bordes redondeados */

}

  
  .CardContainer .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.5s;

  }

  @import '~bootstrap-icons/font/bootstrap-icons.css';
  
  .CardContainer .front {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(3, 35, 54);
    transform: rotateY(0deg);
    box-shadow: 0 5px 10px #000;
    color: white;
  }
  i{
    font-size: 20vh; /* Ajusta el tamaño del icono según tus necesidades */
  }
  
  .CardContainer .back {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    background-color: #065c53;
    transform: rotateY(180deg);
    color: #f3f3f3;
    box-shadow: 0 5px 10px #000;
  }
  .back-title{
    color: #f3f3f3;
    margin-top: 5px;
    font-size: 2.2vh;
  }
  .front-title
  {
    color: #f3f3f3;
    font-size: 2.5vh;
  }
  img{
    height: 60%;
    width: 100%;
    object-fit: cover; /* Ajusta la imagen para que cubra el contenedor manteniendo sus proporciones */
    object-position: center;
  }
  p
  {
    color: #f3f3f3;
    font-family: sans-serif;
    font-size: 2vh;
    text-align: start;
    margin-top: 5px;
    margin-left: 5px;
  }
  
  .CardContainer:hover .front {
    transform: rotateY(-180deg);
  }
  
  .CardContainer:hover .back {
    transform: rotateY(0deg);
  }
  @media (max-width: 768px) {
    .CardContainer {
      display: flex;
      flex-wrap: wrap;  
      max-width: 22vh; /* Tamaño máximo */
      max-height: 29vh; /* Tamaño máximo */
      margin: 2vh;
      border-radius: 10px; /* Asegura que el contenedor también tenga bordes redondeados */
    
    }
    
      .CardContainer .face {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 10px;
        overflow: hidden;
        transition: transform 0.5s;
    
      }
    
      @import '~bootstrap-icons/font/bootstrap-icons.css';
      
      .CardContainer .front {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(3, 35, 54);
        transform: rotateY(0deg);
        box-shadow: 0 5px 10px #000;
        color: white;
      }
      i{
        font-size: 10vh; /* Ajusta el tamaño del icono según tus necesidades */
      }
      
      .CardContainer .back {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: flex-start;
        background-color: #065c53;
        transform: rotateY(180deg);
        color: #f3f3f3;
        box-shadow: 0 5px 10px #000;
      }
      .back-title{
        color: #f3f3f3;
        margin-top: 5px;
        font-size: 2.2vh;
      }
      .front-title
      {
        color: #f3f3f3;
        font-size: 2vh;
      }
      img{
        height: 60%;
        width: 100%;
        object-fit: cover; /* Ajusta la imagen para que cubra el contenedor manteniendo sus proporciones */
        object-position: center;
      }
      p
      {
        color: #f3f3f3;
        font-family: sans-serif;
        font-size: 1.5vh;
        text-align: start;
        margin-top: 5px;
        margin-left: 5px;
      }
      
  }