.About {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    background-color: black;
    position: relative;
    width: 100%; /* Ancho del contenedor */
    height: 100vh; /* Alto del contenedor */
  }
  .Overview_Section {
    width: 80%; /* Ancho del contenedor */
    height: 35%; /* Alto del contenedor */
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna (uno debajo del otro) */
    justify-content: center; /* Centra los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
  }
  
  .Overview_Section h1, .Overview_Section h2, .Overview_Section p {
    margin: 7px 0; /* Añade un poco de espacio entre los elementos */
    width: 80%; /* Asegura que los elementos tengan un ancho específico */
    text-align: left; /* Alinea el texto a la izquierda */
  }
  .TitleAbout{
    color: white;
    font-size: 4rem;
    font-family: 'Poetsen One', sans-serif;
  } 
  .SubtitleAbout{
    color: #14e8b9;
    font-size: 1.5rem;
    font-family: 'Poetsen One', sans-serif;
  }
  a {
    color: #007bff; /* Azul claro */
  }
   
  .Cards_Section
  {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ancho del contenedor */
    height: 70%; /* Alto del contenedor */
  }
  .textAbout{
    color: white;
    font-size: 3vh;
  }
  @media (max-width: 768px) {
    .About {
      display: flex;
      flex-direction: column;
      justify-content: center; /* Centra los elementos verticalmente */
      align-items: center; /* Centra los elementos horizontalmente */
      background-color: black;
      position: relative;
      width: 100%; /* Ancho del contenedor */
      height: 100vh; /* Alto del contenedor */
    }
    .Overview_Section {
      width: 80%; /* Ancho del contenedor */
      height: 35%; /* Alto del contenedor */
      display: flex;
      flex-direction: column; /* Alinea los elementos en una columna (uno debajo del otro) */
      justify-content: center; /* Centra los elementos verticalmente */
      align-items: center; /* Centra los elementos horizontalmente */
    }
    
    .Overview_Section h1, .Overview_Section h2, .Overview_Section p {
      margin: 7px 0; /* Añade un poco de espacio entre los elementos */
      width: 80%; /* Asegura que los elementos tengan un ancho específico */
      text-align: left; /* Alinea el texto a la izquierda */
    }
    .TitleAbout{
      color: white;
      font-size: 4vh;
      font-family: 'Poetsen One', sans-serif;
    } 
    .SubtitleAbout{
      color: #14e8b9;
      font-size: 3vh;
      font-family: 'Poetsen One', sans-serif;
    }
    a {
      color: #007bff; /* Azul claro */
    }
     
    .Cards_Section
    {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%; /* Ancho del contenedor */
      height: 70%; /* Alto del contenedor */
    }
    .textAbout{
      color: white;
      font-size: 2.2vh;
      text-align: center;
    }
  }