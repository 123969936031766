.ReelContainer{
  display: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #030c0b;
  width: 100%; /* Ancho del contenedor */
  height: 100vh; /* Alto del contenedor */
  
  
}
.TitleReelContainer
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}
.TitleReelContainer h1
{
  color: #14e8b9;
  font-size: 4rem;
  font-family: 'Poetsen One', sans-serif;
}
.VideoPlayerWrapper {
    display: flex;
    justify-content: center; /* Centra horizontalmente el contenido */
    align-items: center; /* Centra verticalmente el contenido */
    position: relative;
    width: 100%; /* Ancho del contenedor */
    height: 75%; /* Alto del contenedor */
    
  }
  
  .ReactPlayer {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que el video ocupe todo el contenedor sin deformarse */
  }
  @media (max-width: 768px) {
    
    .TitleReelContainer h1
    {
      color: #14e8b9;
      font-size: 4vh;
      font-family: 'Poetsen One', sans-serif;
      text-align: center;
    }
    .ReelContainer{
      display: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background-color: #030c0b;
      width: 100%; /* Ancho del contenedor */
      height: 50vh; /* Alto del contenedor */
    
    }
  }