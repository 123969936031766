/* Estilos para pantallas de computador */
.InfoDiv {
    display: flex;
    flex-wrap: wrap;
    background-image: url(/public/Background.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 110vh;
}

.InfoDiv_inv {
    display: flex;
    flex-wrap: wrap;
    background-color: #030c0b;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 110vh;
}

.titleContainer {
    color: white;
    font-size: 8vh;
    font-family: 'Poetsen One', sans-serif;
}

.left_Info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 55%;
}

.right_Info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 80%;
    width: 45%;
}

.top_Div {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: 20%;
}

.ReactPlayerI {
    object-fit: cover; /* Asegura que el video ocupe todo el contenedor sin deformarse */
}

.Top_Title {
    display: flex;
    justify-content: center;
    align-items: end;
    text-align: justify;
    width: 90%;
    height: 60%;
}

.Icons_Div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 40%;
}

.IconImg {
    height: 14vh;
    width: 12vh;
    display: inline-block; /* Asegura que el elemento se comporte correctamente con la animación */
    object-fit: contain; /* Ajusta la imagen para que cubra el contenedor manteniendo sus proporciones */
    object-position: center;
    margin: 5vh;
}

.DescriptionText {
    font-size: 3vh;
    font-family: sans-serif;
}

.right_Info_Inv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 55%;
}

.left_Info_Inv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 80%;
    width: 45%;
}

@media (max-width: 768px) {
    .InfoDiv {
        background-image: url(/public/Background.jpg);
        background-size: cover;
        background-position: center;
        width: 100%;
        height: auto;
    }

    .InfoDiv_inv {
        background-color: #030c0b;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: auto;
    }

    .titleContainer {
        color: white;
        font-size: 6vh;
        font-family: 'Poetsen One', sans-serif;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .left_Info {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        order: 1;
    }

    .right_Info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
        width: 100%;
        order: 2;
    }

    .top_Div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: auto;
    }

    .ReactPlayerI {
        object-fit: contain;
    }

    .Top_Title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: justify;
        width: 90%;
        height: auto;
    }

    .Icons_Div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .IconImg {
        height: auto;
        width: 7vh;
        object-fit: contain;
        object-position: center;
        margin-left: 2vh;
        margin-right: 2vh;
        margin-top: 1vh;
    }

    .DescriptionText {
        font-size: 1.9vh;
        font-family: sans-serif;
        margin-top: 3vh;
    }

    .right_Info_Inv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        order: 1;
    }

    .left_Info_Inv {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
        width: 100%;
        order: 2;
    }
}