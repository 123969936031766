/* Definir la animación de salto */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  /* Aplicar la animación al icono cuando se hace hover */
  .Icons {
    height: 17vh;
    width: 15vh;
    display: inline-block; /* Asegura que el elemento se comporte correctamente con la animación */
    object-fit: contain; /* Ajusta la imagen para que cubra el contenedor manteniendo sus proporciones */
    object-position: center;
    margin: 9vh;
  }
  
  .Icons:hover {
    animation: bounce 1s; /* Duración de la animación */
  }
  .IconsDiv
  {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  
  @media (max-width: 540px) and (max-height: 960px) {

    .Icons {
      height: 9vh;
      width: 7vh;
      display: inline-block; /* Asegura que el elemento se comporte correctamente con la animación */
      object-fit: contain; /* Ajusta la imagen para que cubra el contenedor manteniendo sus proporciones */
      object-position: center;
      margin: 1.5vh;
    }
  }