.HeroSection {
    position: relative;
    width: 100%;
    height: 90vh;
    background-image: url('/public/Background.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .TextContainer {
    position: absolute;
    top: 5%;
    left: 40%;
    transform: translateX(-50%);
    text-align: left;
    z-index: 2; /* Asegura que el texto esté por encima del renderizado de Spline */
    pointer-events: none;
  }
  
  .Title {
    position: relative;
    color: #14e8b9;
    font-size: 5rem;
    margin: 0;
    overflow: hidden; /* Oculta el texto mientras se escribe */
    white-space: nowrap; /* Evita el salto de línea */
    animation: typing-animation 2s steps(40, end) forwards; /* Animación de escribir */
    font-family: 'Poetsen One', sans-serif;
  }
  
  .Subtitle {
    color: white;
    font-size: 1.5rem;
    margin: 0;
    overflow: hidden; /* Oculta el texto mientras se escribe */
    white-space: nowrap; /* Evita el salto de línea */
    animation: typing-animation 2s steps(40, end) 0.5s forwards; /* Animación de escribir */
    font-family: 'Poetsen One', sans-serif;
    
  }
  
  @keyframes typing-animation {
    from {
      width: 0; /* Empieza sin ancho */
    }
    to {
      width: 100%; /* Se expande hasta completar el texto */
    }
  }
  
  .SplineContainer {
    width: 100%;
    height: 100%;
    z-index: 1; /* Asegura que el renderizado de Spline esté detrás del texto */
  }
  
  .SplineContainer canvas {
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 768px)  {

    .TextContainer {
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      z-index: 2; /* Asegura que el texto esté por encima del renderizado de Spline */
      pointer-events: none;
    }

    .Title {
      position: relative;
      color: #14e8b9;
      font-size: 7vh;
      margin: 0;
      overflow: hidden; /* Oculta el texto mientras se escribe */
      white-space: nowrap; /* Evita el salto de línea */
      animation: typing-animation 2s steps(40, end) forwards; /* Animación de escribir */
      font-family: 'Poetsen One', sans-serif;
    }
    
    .Subtitle {
      color: white;
      font-size: 1.7vh;
      margin: 0;
      overflow: hidden; /* Oculta el texto mientras se escribe */
      white-space: nowrap; /* Evita el salto de línea */
      animation: typing-animation 2s steps(40, end) 0.5s forwards; /* Animación de escribir */
      font-family: 'Poetsen One', sans-serif;
      
    }
      

  }