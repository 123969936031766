/* Navbar.css */
nav {
    z-index: 10; /* Valor alto para estar por encima del Hero */
    padding: .5rem;
    background-color: #1b232a;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  nav h2 {
    color: white;
    font-weight: 400;
  }
  
  nav h2 span {
    font-weight: bold;
  }
  
  nav a {
    color: white;
    text-decoration: none;
    margin-right: 1rem;
  }
  
  nav .links a:hover {
    color: #14e8b9;
    text-decoration: none;
    margin-right: 1rem;
  }
  
  nav .links {
    position: absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all 0.5s ease;
  }
  
  nav .links a {
    color: white;
    font-size: 3rem;
    display: block;
  }
  
  @media (min-width: 768px) {
    nav .links {
      position: initial;
      margin: 0;
      display: block;
    }
  
    nav .links a {
      font-size: 1rem;
      color: white;
      display: inline;
    }
  }
  
  nav .links.active {
    width: 100%;
    display: block;
    z-index: 9;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  nav .links.active a {
    font-size: 2rem;
    margin-top: 1rem;
    color: white;
  }
  
  nav .links.active a:hover {
    color: #14e8b9;
    text-decoration: none;
    margin-right: 1rem;
  }
  
  nav .burguer {
    @media (min-width: 768px) {
      z-index: 9;
      display: none;
    }
  }
  
  .bg-div {
    background-color: #222;
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 100%;
    height: 100%;
    z-index: 8;
    transition: all 0.6s ease;
  }
  
  .bg-div.active {
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  