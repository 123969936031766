.BodyContainer{
    background-image: url('/public/Background.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}
.TittleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 30%;
}
.IconsContainer{
    width: 100%;
    height: 70%;

}
.SubtitleTecno
{
    color: #14e8b9;
    font-size: 1.5rem;
    font-family: 'Poetsen One', sans-serif;
}
.TitileTecno
{
    color: white;
    font-size: 4rem;
    font-family: 'Poetsen One', sans-serif;
}
@media (max-width: 768px) {

    .BodyContainer{
        background-image: url('/public/Background.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 70vh;
    }
    .SubtitleTecno
    {
        color: #14e8b9;
        font-size: 3vh;
        font-family: 'Poetsen One', sans-serif;
    }
    .TitileTecno
    {
        color: white;
        font-size: 5vh;
        font-family: 'Poetsen One', sans-serif;
        text-align: center;
    }

}