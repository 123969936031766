.ReferencesContainer
{
    background-color: #030c0b;
    width: 100%;
    height: 200vh;
}
.imgReference
{
    width: 40vh;
    height: 40vh;
    object-fit: cover; 
    object-position: center;
    margin: 5vh;
}
.titleReferencesContainer
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}
.titleReferences
{
    color: white;
    font-size: 5vh;
    font-family: 'Poetsen One', sans-serif;
}
.imgReferenceContainer
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 90%;
}
@media (max-width: 768px) {
    .titleReferences
    { 
        color: white;
        font-size: 5vh;
        font-family: 'Poetsen One', sans-serif;
        text-align: center;
        margin-top: 3vh;
    }
    .imgReference
    {
        width: 45vh;
        height: 45vh;
        object-fit: cover; 
        object-position: center;
        margin: 5vh;
    }
    .ReferencesContainer
    {
        background-color: #030c0b;
        width: 100%;
        height: auto;
    }
    .imgReferenceContainer
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .titleReferencesContainer
    {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

}