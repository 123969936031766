/* Estilos para el contenedor principal de Contacts */
.ContactsContainer 
  {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70vh;
    background-color: black;
  }
  .ContactsBody
  {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 100%;
    height: 100vh;

  }
  
  /* Estilos para la foto de perfil */
  .Photo 
  {
    height: 400px;
    width: 350px;
  }

  /* Estilos para el contenedor derecho */
  .RightContainer 
  {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .RightContainer h2 
  {
    margin-bottom: 50px;
    color: white;
  }
  
  /* Estilos para el contenedor izquierdo */
  .LeftContainer 
   {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  

.IconsContact 
  {
    font-size: 7vh; /* Tamaño de la fuente de los iconos */
    margin: 10px; /* Ajusta el espacio entre los iconos */
    color: white;
    transition: color 0.3s;
  }
  
  .IconsContact:hover 
  {
    color: #14e8b9;
  }
  @media (max-width: 540px) and (max-height: 960px) {

    /* Estilos para el contenedor principal de Contacts */
    .ContactsContainer 
    {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    background-color: black;
    }

    /* Estilos para la foto de perfil */
    .Photo 
    {
    height: 30vh;
    width: 25vh;
    }

    /* Estilos para el contenedor derecho */
    .RightContainer 
    {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }

    .RightContainer h2 
    {
    margin-bottom: 50px;
    color: white;
    font-size: 2.2vh;
    text-align: center;
    }

    /* Estilos para el contenedor izquierdo */
    .LeftContainer 
    {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }


    .IconsContact 
    {
    font-size: 6vh; /* Tamaño de la fuente de los iconos */
    margin: 10px; /* Ajusta el espacio entre los iconos */
    color: white;
    transition: color 0.3s;
    }

    .IconsContact:hover 
    {
    color: #14e8b9;
    }
}
